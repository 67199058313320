export enum MapPinIconDefault {
  APARTMENT = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_772_62518)">
              <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
              <path d="M28.6605 15.8334H22.0065C21.2659 15.8334 20.6665 16.4327 20.6665 17.1734V20.9314L17.5319 24.026C17.4379 24.1189 17.3737 24.2376 17.3474 24.3671C17.321 24.4965 17.3337 24.6309 17.3839 24.7531C17.434 24.8753 17.5193 24.9798 17.629 25.0535C17.7387 25.1272 17.8678 25.1666 17.9999 25.1667V28.5C17.9999 28.6768 18.0701 28.8464 18.1951 28.9714C18.3201 29.0964 18.4897 29.1667 18.6665 29.1667H29.3332C29.51 29.1667 29.6796 29.0964 29.8046 28.9714C29.9296 28.8464 29.9998 28.6768 29.9998 28.5V17.1727C29.9998 16.4327 29.4012 15.8334 28.6605 15.8334ZM23.2812 27.8334H19.3332V24.1214L21.3245 22.1554L23.2812 24.1574V27.8334ZM24.6665 20.5H23.3332V19.1667H24.6665V20.5ZM27.3332 25.8334H25.9998V24.5H27.3332V25.8334ZM27.3332 23.1667H25.9998V21.8334H27.3332V23.1667ZM27.3332 20.5H25.9998V19.1667H27.3332V20.5Z" fill="#26313D"/>
              <path d="M20.6665 24.5H21.9998V25.8334H20.6665V24.5Z" fill="#26313D"/>
              </g>
              </svg>
              `,
  VILLA = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_772_62500)">
          <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
          <path d="M16.6547 21.4223L24.0001 16.5025L31.3452 21.4223C31.554 21.562 31.821 21.479 31.9416 21.237C32.0619 20.9947 31.9904 20.6848 31.7819 20.5451L28.0835 18.0682L28.5057 18.3509V16.6184C28.5057 16.4308 28.3744 16.279 28.2128 16.279H27.2613C27.0996 16.279 26.9686 16.4308 26.9686 16.6184V17.3215L24.0002 15.333L16.2184 20.5451C16.0098 20.6848 15.9378 20.9947 16.0587 21.237C16.1791 21.4791 16.4463 21.562 16.6547 21.4223L16.6547 21.4223Z" fill="#26313D"/>
          <path d="M30.8325 29.325V22.1406C30.8325 21.9525 30.7176 21.7219 30.5771 21.6278L24.2561 17.3941C24.1157 17.2999 23.886 17.2999 23.7456 17.3941L17.4242 21.6279C17.2839 21.7221 17.1689 21.9527 17.1689 22.1407V29.3252C17.1689 29.5133 17.3016 29.6671 17.4637 29.6671H19.8673C20.0294 29.6671 20.162 29.5133 20.162 29.3252V23.6684C20.162 23.4803 20.2947 23.3265 20.4567 23.3265H22.7953C22.9574 23.3265 23.09 23.4804 23.09 23.6684V29.3252C23.09 29.5133 23.2226 29.6671 23.3847 29.6671H30.5379C30.7 29.6671 30.8326 29.5133 30.8326 29.3252L30.8325 29.325ZM25.1394 25.1684C25.1394 24.9804 25.272 24.8265 25.4341 24.8265H26.6748V25.9994H25.1395L25.1394 25.1684ZM25.4341 27.8555C25.272 27.8555 25.1394 27.7016 25.1394 27.5136V26.6829H26.6747V27.8554L25.4341 27.8555ZM28.8238 27.5137C28.8238 27.7017 28.691 27.8556 28.5291 27.8556H27.2644V26.683H28.8239L28.8238 27.5137ZM28.8238 25.9992H27.2644V24.8264H28.5291C28.691 24.8264 28.8238 24.9803 28.8238 25.1683L28.8238 25.9992Z" fill="#26313D"/>
          </g>
          </svg>
          `,
  TOWNHOUSE = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_772_62477)">
              <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
              <path d="M27.8683 18.947L25.8558 17.5963H28.2695L29.9214 18.947H27.8683ZM24.0002 17.7358C24.0546 17.7358 24.1083 17.752 24.1552 17.7833L27.544 20.057V19.3995L24.0003 17.0214L20.4565 19.3995V20.057L23.8453 17.7833C23.8922 17.752 23.946 17.7358 24.0003 17.7358H24.0002ZM28.1002 19.5039V20.5795C28.1002 20.682 28.0433 20.7764 27.9527 20.8251C27.9115 20.847 27.8671 20.8576 27.8221 20.8576C27.7677 20.8576 27.7133 20.842 27.6665 20.8108L27.3483 20.597V21.6389H29.5778L29.5784 19.5038L28.1002 19.5039ZM27.349 23.5464H31.8402L30.189 22.1958H27.349V23.5464ZM16.1602 23.5464H20.6527V22.1958H17.8114L16.1602 23.5464ZM16.702 24.1033H20.6526V27.979H16.702V24.1033ZM17.7964 26.8338H18.3983V26.3195H17.7964V26.8338ZM18.9552 25.2482V25.7632H19.5583V25.2482H18.9552ZM18.9552 26.3195V26.8339H19.5583V26.3195H18.9552ZM17.7964 25.2482V25.7632H18.3983V25.2482H17.7964ZM24.0001 18.3496L26.792 20.2233V27.979H24.8369V25.0997H23.1639V27.9784H21.2088L21.2094 20.2226L24.0001 18.3496ZM23.2883 21.6938C23.2883 22.0863 23.6076 22.4057 24.0001 22.4057C24.3933 22.4057 24.7133 22.0863 24.7133 21.6938C24.7133 21.3013 24.3933 20.982 24.0001 20.982C23.6076 20.982 23.2883 21.3007 23.2883 21.6938ZM27.3489 24.1033H31.2991V27.979H27.3489V24.1033ZM28.4433 26.8338H29.0452V26.3195H28.4433V26.8338ZM29.0452 25.7632V25.2482H28.4433V25.7632H29.0452ZM29.6021 26.8338H30.2039V26.3195H29.6021V26.8338ZM30.2039 25.2482H29.6021V25.7632H30.2039V25.2482Z" fill="#26313D"/>
              </g>
              </svg>`,
  FLOOR_THROUGH = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_772_62518)">
                  <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
                  <path d="M28.6605 15.8334H22.0065C21.2659 15.8334 20.6665 16.4327 20.6665 17.1734V20.9314L17.5319 24.026C17.4379 24.1189 17.3737 24.2376 17.3474 24.3671C17.321 24.4965 17.3337 24.6309 17.3839 24.7531C17.434 24.8753 17.5193 24.9798 17.629 25.0535C17.7387 25.1272 17.8678 25.1666 17.9999 25.1667V28.5C17.9999 28.6768 18.0701 28.8464 18.1951 28.9714C18.3201 29.0964 18.4897 29.1667 18.6665 29.1667H29.3332C29.51 29.1667 29.6796 29.0964 29.8046 28.9714C29.9296 28.8464 29.9998 28.6768 29.9998 28.5V17.1727C29.9998 16.4327 29.4012 15.8334 28.6605 15.8334ZM23.2812 27.8334H19.3332V24.1214L21.3245 22.1554L23.2812 24.1574V27.8334ZM24.6665 20.5H23.3332V19.1667H24.6665V20.5ZM27.3332 25.8334H25.9998V24.5H27.3332V25.8334ZM27.3332 23.1667H25.9998V21.8334H27.3332V23.1667ZM27.3332 20.5H25.9998V19.1667H27.3332V20.5Z" fill="#26313D"/>
                  <path d="M20.6665 24.5H21.9998V25.8334H20.6665V24.5Z" fill="#26313D"/>
                  </g>
                  </svg>
                  `,
  DUPLEX = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_772_62477)">
              <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
              <path d="M27.8683 18.947L25.8558 17.5963H28.2695L29.9214 18.947H27.8683ZM24.0002 17.7358C24.0546 17.7358 24.1083 17.752 24.1552 17.7833L27.544 20.057V19.3995L24.0003 17.0214L20.4565 19.3995V20.057L23.8453 17.7833C23.8922 17.752 23.946 17.7358 24.0003 17.7358H24.0002ZM28.1002 19.5039V20.5795C28.1002 20.682 28.0433 20.7764 27.9527 20.8251C27.9115 20.847 27.8671 20.8576 27.8221 20.8576C27.7677 20.8576 27.7133 20.842 27.6665 20.8108L27.3483 20.597V21.6389H29.5778L29.5784 19.5038L28.1002 19.5039ZM27.349 23.5464H31.8402L30.189 22.1958H27.349V23.5464ZM16.1602 23.5464H20.6527V22.1958H17.8114L16.1602 23.5464ZM16.702 24.1033H20.6526V27.979H16.702V24.1033ZM17.7964 26.8338H18.3983V26.3195H17.7964V26.8338ZM18.9552 25.2482V25.7632H19.5583V25.2482H18.9552ZM18.9552 26.3195V26.8339H19.5583V26.3195H18.9552ZM17.7964 25.2482V25.7632H18.3983V25.2482H17.7964ZM24.0001 18.3496L26.792 20.2233V27.979H24.8369V25.0997H23.1639V27.9784H21.2088L21.2094 20.2226L24.0001 18.3496ZM23.2883 21.6938C23.2883 22.0863 23.6076 22.4057 24.0001 22.4057C24.3933 22.4057 24.7133 22.0863 24.7133 21.6938C24.7133 21.3013 24.3933 20.982 24.0001 20.982C23.6076 20.982 23.2883 21.3007 23.2883 21.6938ZM27.3489 24.1033H31.2991V27.979H27.3489V24.1033ZM28.4433 26.8338H29.0452V26.3195H28.4433V26.8338ZM29.0452 25.7632V25.2482H28.4433V25.7632H29.0452ZM29.6021 26.8338H30.2039V26.3195H29.6021V26.8338ZM30.2039 25.2482H29.6021V25.7632H30.2039V25.2482Z" fill="#26313D"/>
              </g>
              </svg>`,
  LAND = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_772_62522)">
          <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
          <path d="M31.5682 28.692L29.4562 23.716C29.3922 23.5719 29.2482 23.476 29.0882 23.476H25.5682V25.22C25.5682 26.084 24.8642 26.804 23.9842 26.804C23.1202 26.804 22.4002 26.0999 22.4002 25.22L22.4002 23.476H18.8802C18.7202 23.476 18.5762 23.5719 18.5122 23.716L16.4322 28.692C16.3842 28.8039 16.4002 28.9479 16.4642 29.06C16.5442 29.172 16.6562 29.236 16.8002 29.236H31.2002C31.3282 29.236 31.4562 29.172 31.5202 29.06C31.6162 28.948 31.6162 28.804 31.5682 28.692H31.5682Z" fill="black"/>
          <path d="M20.4322 21.252H23.4082V25.204C23.4082 25.524 23.6801 25.796 24.0001 25.796C24.3201 25.796 24.5921 25.524 24.5921 25.204L24.5922 21.252H27.5682C27.7922 21.252 27.9682 21.0761 27.9682 20.852L27.9682 16.164C27.9682 15.94 27.7922 15.764 27.5682 15.764H20.4322C20.2082 15.764 20.0322 15.94 20.0322 16.164V20.852C20.0322 21.076 20.2083 21.252 20.4322 21.252L20.4322 21.252ZM22.0002 17.22H25.9842C26.2082 17.22 26.3842 17.396 26.3842 17.62C26.3842 17.8441 26.2082 18.02 25.9842 18.02H22.0002C21.7762 18.02 21.6002 17.8441 21.6002 17.62C21.6162 17.396 21.7921 17.22 22.0002 17.22ZM22.0002 19.012H25.9842C26.2082 19.012 26.3842 19.188 26.3842 19.412C26.3842 19.6361 26.2082 19.812 25.9842 19.812H22.0002C21.7762 19.812 21.6002 19.6361 21.6002 19.412C21.6162 19.1881 21.7921 19.012 22.0002 19.012Z" fill="black"/>
          </g>
          </svg>
          `,
}

export enum MapPinIconActive {
  APARTMENT = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_772_62505)">
              <rect x="12" y="10.5" width="24" height="24" rx="12" fill="#35BF9A" shape-rendering="crispEdges"/>
              <path d="M28.6605 15.8334H22.0065C21.2659 15.8334 20.6665 16.4327 20.6665 17.1734V20.9314L17.5319 24.026C17.4379 24.1189 17.3737 24.2376 17.3474 24.3671C17.321 24.4965 17.3337 24.6309 17.3839 24.7531C17.434 24.8753 17.5193 24.9798 17.629 25.0535C17.7387 25.1272 17.8678 25.1666 17.9999 25.1667V28.5C17.9999 28.6768 18.0701 28.8464 18.1951 28.9714C18.3201 29.0964 18.4897 29.1667 18.6665 29.1667H29.3332C29.51 29.1667 29.6796 29.0964 29.8046 28.9714C29.9296 28.8464 29.9998 28.6768 29.9998 28.5V17.1727C29.9998 16.4327 29.4012 15.8334 28.6605 15.8334ZM23.2812 27.8334H19.3332V24.1214L21.3245 22.1554L23.2812 24.1574V27.8334ZM24.6665 20.5H23.3332V19.1667H24.6665V20.5ZM27.3332 25.8334H25.9998V24.5H27.3332V25.8334ZM27.3332 23.1667H25.9998V21.8334H27.3332V23.1667ZM27.3332 20.5H25.9998V19.1667H27.3332V20.5Z" fill="white"/>
              <path d="M20.6665 24.5H21.9998V25.8334H20.6665V24.5Z" fill="white"/>
              </g>
              </svg>
              `,
  VILLA = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_772_62495)">
            <rect x="12" y="10.5" width="24" height="24" rx="12" fill="#35BF9A" shape-rendering="crispEdges"/>
            <path d="M16.6547 21.4223L24.0001 16.5025L31.3452 21.4223C31.554 21.562 31.821 21.479 31.9416 21.237C32.0619 20.9947 31.9904 20.6848 31.7819 20.5451L28.0835 18.0682L28.5057 18.3509V16.6184C28.5057 16.4308 28.3744 16.279 28.2128 16.279H27.2613C27.0996 16.279 26.9686 16.4308 26.9686 16.6184V17.3215L24.0002 15.333L16.2184 20.5451C16.0098 20.6848 15.9378 20.9947 16.0587 21.237C16.1791 21.4791 16.4463 21.562 16.6547 21.4223L16.6547 21.4223Z" fill="white"/>
            <path d="M30.8325 29.325V22.1405C30.8325 21.9525 30.7176 21.7219 30.5771 21.6277L24.2561 17.394C24.1157 17.2999 23.886 17.2999 23.7456 17.394L17.4242 21.6279C17.2839 21.722 17.1689 21.9526 17.1689 22.1407V29.3252C17.1689 29.5132 17.3016 29.667 17.4637 29.667H19.8673C20.0294 29.667 20.162 29.5132 20.162 29.3252V23.6683C20.162 23.4803 20.2947 23.3264 20.4567 23.3264H22.7953C22.9574 23.3264 23.09 23.4803 23.09 23.6683V29.3252C23.09 29.5132 23.2226 29.667 23.3847 29.667H30.5379C30.7 29.667 30.8326 29.5132 30.8326 29.3252L30.8325 29.325ZM25.1394 25.1684C25.1394 24.9803 25.272 24.8265 25.4341 24.8265H26.6748V25.9993H25.1395L25.1394 25.1684ZM25.4341 27.8554C25.272 27.8554 25.1394 27.7016 25.1394 27.5135V26.6828H26.6747V27.8554L25.4341 27.8554ZM28.8238 27.5136C28.8238 27.7017 28.691 27.8555 28.5291 27.8555H27.2644V26.683H28.8239L28.8238 27.5136ZM28.8238 25.9992H27.2644V24.8263H28.5291C28.691 24.8263 28.8238 24.9802 28.8238 25.1682L28.8238 25.9992Z" fill="white"/>
            </g>
            </svg>
            `,
  TOWNHOUSE = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_772_62483)">
              <rect x="12" y="10.5" width="24" height="24" rx="12" fill="#35BF9A" shape-rendering="crispEdges"/>
              <path d="M27.8683 18.9469L25.8558 17.5963H28.2695L29.9214 18.9469H27.8683ZM24.0002 17.7357C24.0546 17.7357 24.1083 17.7519 24.1552 17.7832L27.544 20.0569V19.3994L24.0003 17.0213L20.4565 19.3994V20.0569L23.8453 17.7832C23.8922 17.7519 23.946 17.7357 24.0003 17.7357H24.0002ZM28.1002 19.5038V20.5795C28.1002 20.682 28.0433 20.7763 27.9527 20.8251C27.9115 20.847 27.8671 20.8576 27.8221 20.8576C27.7677 20.8576 27.7133 20.842 27.6665 20.8107L27.3483 20.597V21.6388H29.5778L29.5784 19.5038L28.1002 19.5038ZM27.349 23.5464H31.8402L30.189 22.1957H27.349V23.5464ZM16.1602 23.5464H20.6527V22.1957H17.8114L16.1602 23.5464ZM16.702 24.1032H20.6526V27.979H16.702V24.1032ZM17.7964 26.8338H18.3983V26.3194H17.7964V26.8338ZM18.9552 25.2482V25.7632H19.5583V25.2482H18.9552ZM18.9552 26.3194V26.8338H19.5583V26.3194H18.9552ZM17.7964 25.2482V25.7632H18.3983V25.2482H17.7964ZM24.0001 18.3495L26.792 20.2232V27.979H24.8369V25.0996H23.1639V27.9783H21.2088L21.2094 20.2226L24.0001 18.3495ZM23.2883 21.6938C23.2883 22.0863 23.6076 22.4057 24.0001 22.4057C24.3933 22.4057 24.7133 22.0863 24.7133 21.6938C24.7133 21.3013 24.3933 20.9819 24.0001 20.9819C23.6076 20.9819 23.2883 21.3006 23.2883 21.6938ZM27.3489 24.1032H31.2991V27.979H27.3489V24.1032ZM28.4433 26.8338H29.0452V26.3194H28.4433V26.8338ZM29.0452 25.7631V25.2481H28.4433V25.7631H29.0452ZM29.6021 26.8338H30.2039V26.3194H29.6021V26.8338ZM30.2039 25.2482H29.6021V25.7632H30.2039V25.2482Z" fill="white"/>
              </g>
              </svg>
              `,
  FLOOR_THROUGH = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_772_62507)">
                    <rect x="12" y="10.5" width="24" height="24" rx="12" fill="#35BF9A" shape-rendering="crispEdges"/>
                    <path d="M28.6605 15.8334H22.0065C21.2659 15.8334 20.6665 16.4327 20.6665 17.1734V20.9314L17.5319 24.026C17.4379 24.1189 17.3737 24.2376 17.3474 24.3671C17.321 24.4965 17.3337 24.6309 17.3839 24.7531C17.434 24.8753 17.5193 24.9798 17.629 25.0535C17.7387 25.1272 17.8678 25.1666 17.9999 25.1667V28.5C17.9999 28.6768 18.0701 28.8464 18.1951 28.9714C18.3201 29.0964 18.4897 29.1667 18.6665 29.1667H29.3332C29.51 29.1667 29.6796 29.0964 29.8046 28.9714C29.9296 28.8464 29.9998 28.6768 29.9998 28.5V17.1727C29.9998 16.4327 29.4012 15.8334 28.6605 15.8334ZM23.2812 27.8334H19.3332V24.1214L21.3245 22.1554L23.2812 24.1574V27.8334ZM24.6665 20.5H23.3332V19.1667H24.6665V20.5ZM27.3332 25.8334H25.9998V24.5H27.3332V25.8334ZM27.3332 23.1667H25.9998V21.8334H27.3332V23.1667ZM27.3332 20.5H25.9998V19.1667H27.3332V20.5Z" fill="white"/>
                    <path d="M20.6665 24.5H21.9998V25.8334H20.6665V24.5Z" fill="white"/>
                    </g>
                    </svg>
                    `,
  DUPLEX = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_772_62486)">
            <rect x="12" y="10.5" width="24" height="24" rx="12" fill="#35BF9A" shape-rendering="crispEdges"/>
            <path d="M27.8683 18.9469L25.8558 17.5963H28.2695L29.9214 18.9469H27.8683ZM24.0002 17.7357C24.0546 17.7357 24.1083 17.7519 24.1552 17.7832L27.544 20.0569V19.3994L24.0003 17.0213L20.4565 19.3994V20.0569L23.8453 17.7832C23.8922 17.7519 23.946 17.7357 24.0003 17.7357H24.0002ZM28.1002 19.5038V20.5795C28.1002 20.682 28.0433 20.7763 27.9527 20.8251C27.9115 20.847 27.8671 20.8576 27.8221 20.8576C27.7677 20.8576 27.7133 20.842 27.6665 20.8107L27.3483 20.597V21.6388H29.5778L29.5784 19.5038L28.1002 19.5038ZM27.349 23.5464H31.8402L30.189 22.1957H27.349V23.5464ZM16.1602 23.5464H20.6527V22.1957H17.8114L16.1602 23.5464ZM16.702 24.1032H20.6526V27.979H16.702V24.1032ZM17.7964 26.8338H18.3983V26.3194H17.7964V26.8338ZM18.9552 25.2482V25.7632H19.5583V25.2482H18.9552ZM18.9552 26.3194V26.8338H19.5583V26.3194H18.9552ZM17.7964 25.2482V25.7632H18.3983V25.2482H17.7964ZM24.0001 18.3495L26.792 20.2232V27.979H24.8369V25.0996H23.1639V27.9783H21.2088L21.2094 20.2226L24.0001 18.3495ZM23.2883 21.6938C23.2883 22.0863 23.6076 22.4057 24.0001 22.4057C24.3933 22.4057 24.7133 22.0863 24.7133 21.6938C24.7133 21.3013 24.3933 20.9819 24.0001 20.9819C23.6076 20.9819 23.2883 21.3006 23.2883 21.6938ZM27.3489 24.1032H31.2991V27.979H27.3489V24.1032ZM28.4433 26.8338H29.0452V26.3194H28.4433V26.8338ZM29.0452 25.7631V25.2481H28.4433V25.7631H29.0452ZM29.6021 26.8338H30.2039V26.3194H29.6021V26.8338ZM30.2039 25.2482H29.6021V25.7632H30.2039V25.2482Z" fill="white"/>
            </g>
            </svg>
            `,
  LAND = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_772_62509)">
          <rect x="12" y="10.5" width="24" height="24" rx="12" fill="#35BF9A" shape-rendering="crispEdges"/>
          <path d="M31.5682 28.692L29.4562 23.716C29.3922 23.572 29.2482 23.476 29.0882 23.476H25.5682V25.22C25.5682 26.084 24.8642 26.804 23.9842 26.804C23.1202 26.804 22.4002 26.1 22.4002 25.22L22.4002 23.476H18.8802C18.7202 23.476 18.5762 23.572 18.5122 23.716L16.4322 28.692C16.3842 28.804 16.4002 28.948 16.4642 29.06C16.5442 29.172 16.6562 29.236 16.8002 29.236H31.2002C31.3282 29.236 31.4562 29.172 31.5202 29.06C31.6162 28.9481 31.6162 28.8041 31.5682 28.692H31.5682Z" fill="white"/>
          <path d="M20.4322 21.252H23.4082V25.204C23.4082 25.524 23.6801 25.796 24.0001 25.796C24.3201 25.796 24.5921 25.524 24.5921 25.204L24.5922 21.252H27.5682C27.7922 21.252 27.9682 21.0761 27.9682 20.852L27.9682 16.164C27.9682 15.94 27.7922 15.764 27.5682 15.764H20.4322C20.2082 15.764 20.0322 15.94 20.0322 16.164V20.852C20.0322 21.076 20.2083 21.252 20.4322 21.252L20.4322 21.252ZM22.0002 17.22H25.9842C26.2082 17.22 26.3842 17.396 26.3842 17.62C26.3842 17.8441 26.2082 18.02 25.9842 18.02H22.0002C21.7762 18.02 21.6002 17.8441 21.6002 17.62C21.6162 17.396 21.7921 17.22 22.0002 17.22ZM22.0002 19.012H25.9842C26.2082 19.012 26.3842 19.188 26.3842 19.412C26.3842 19.6361 26.2082 19.812 25.9842 19.812H22.0002C21.7762 19.812 21.6002 19.6361 21.6002 19.412C21.6162 19.1881 21.7921 19.012 22.0002 19.012Z" fill="white"/>
          </g>
          </svg>
          `,
}

export enum MapPinIconVisited {
  APARTMENT = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_772_62526)">
              <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
              <path d="M28.6605 15.8333H22.0065C21.2659 15.8333 20.6665 16.4326 20.6665 17.1733V20.9313L17.5319 24.026C17.4379 24.1188 17.3737 24.2376 17.3474 24.367C17.321 24.4964 17.3337 24.6308 17.3839 24.753C17.434 24.8752 17.5193 24.9798 17.629 25.0534C17.7387 25.1271 17.8678 25.1665 17.9999 25.1666V28.5C17.9999 28.6768 18.0701 28.8464 18.1951 28.9714C18.3201 29.0964 18.4897 29.1666 18.6665 29.1666H29.3332C29.51 29.1666 29.6796 29.0964 29.8046 28.9714C29.9296 28.8464 29.9998 28.6768 29.9998 28.5V17.1726C29.9998 16.4326 29.4012 15.8333 28.6605 15.8333ZM23.2812 27.8333H19.3332V24.1213L21.3245 22.1553L23.2812 24.1573V27.8333ZM24.6665 20.5H23.3332V19.1666H24.6665V20.5ZM27.3332 25.8333H25.9998V24.5H27.3332V25.8333ZM27.3332 23.1666H25.9998V21.8333H27.3332V23.1666ZM27.3332 20.5H25.9998V19.1666H27.3332V20.5Z" fill="#97A4AE"/>
              <path d="M20.6665 24.5H21.9998V25.8333H20.6665V24.5Z" fill="#97A4AE"/>
              </g>
              </svg>
              `,
  VILLA = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_772_62513)">
            <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
            <path d="M16.6547 21.4222L24.0001 16.5024L31.3452 21.4222C31.554 21.5619 31.821 21.479 31.9416 21.2369C32.0619 20.9947 31.9904 20.6847 31.7819 20.545L28.0835 18.0682L28.5057 18.3508V16.6183C28.5057 16.4308 28.3744 16.2789 28.2128 16.2789H27.2613C27.0996 16.2789 26.9686 16.4308 26.9686 16.6183V17.3214L24.0002 15.3329L16.2184 20.545C16.0098 20.6847 15.9378 20.9947 16.0587 21.2369C16.1791 21.479 16.4463 21.562 16.6547 21.4223L16.6547 21.4222Z" fill="#97A4AE"/>
            <path d="M30.8325 29.3249V22.1404C30.8325 21.9524 30.7176 21.7218 30.5771 21.6276L24.2561 17.394C24.1157 17.2998 23.886 17.2998 23.7456 17.394L17.4242 21.6278C17.2839 21.722 17.1689 21.9526 17.1689 22.1406V29.3251C17.1689 29.5131 17.3016 29.667 17.4637 29.667H19.8673C20.0294 29.667 20.162 29.5131 20.162 29.3251V23.6683C20.162 23.4802 20.2947 23.3264 20.4567 23.3264H22.7953C22.9574 23.3264 23.09 23.4802 23.09 23.6683V29.3251C23.09 29.5131 23.2226 29.667 23.3847 29.667H30.5379C30.7 29.667 30.8326 29.5131 30.8326 29.3251L30.8325 29.3249ZM25.1394 25.1683C25.1394 24.9803 25.272 24.8264 25.4341 24.8264H26.6748V25.9993H25.1395L25.1394 25.1683ZM25.4341 27.8554C25.272 27.8554 25.1394 27.7015 25.1394 27.5135V26.6828H26.6747V27.8553L25.4341 27.8554ZM28.8238 27.5136C28.8238 27.7016 28.691 27.8554 28.5291 27.8554H27.2644V26.6829H28.8239L28.8238 27.5136ZM28.8238 25.9991H27.2644V24.8263H28.5291C28.691 24.8263 28.8238 24.9801 28.8238 25.1682L28.8238 25.9991Z" fill="#97A4AE"/>
            </g>
            </svg>
            `,
  TOWNHOUSE = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_772_62489)">
              <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
              <path d="M27.8683 18.9469L25.8558 17.5963H28.2695L29.9214 18.9469H27.8683ZM24.0002 17.7357C24.0546 17.7357 24.1083 17.7519 24.1552 17.7832L27.544 20.0569V19.3994L24.0003 17.0213L20.4565 19.3994V20.0569L23.8453 17.7832C23.8922 17.7519 23.946 17.7357 24.0003 17.7357H24.0002ZM28.1002 19.5038V20.5795C28.1002 20.682 28.0433 20.7763 27.9527 20.8251C27.9115 20.847 27.8671 20.8576 27.8221 20.8576C27.7677 20.8576 27.7133 20.842 27.6665 20.8107L27.3483 20.597V21.6388H29.5778L29.5784 19.5038L28.1002 19.5038ZM27.349 23.5464H31.8402L30.189 22.1957H27.349V23.5464ZM16.1602 23.5464H20.6527V22.1957H17.8114L16.1602 23.5464ZM16.702 24.1032H20.6526V27.979H16.702V24.1032ZM17.7964 26.8338H18.3983V26.3194H17.7964V26.8338ZM18.9552 25.2482V25.7632H19.5583V25.2482H18.9552ZM18.9552 26.3194V26.8338H19.5583V26.3194H18.9552ZM17.7964 25.2482V25.7632H18.3983V25.2482H17.7964ZM24.0001 18.3495L26.792 20.2232V27.979H24.8369V25.0996H23.1639V27.9783H21.2088L21.2094 20.2226L24.0001 18.3495ZM23.2883 21.6938C23.2883 22.0863 23.6076 22.4057 24.0001 22.4057C24.3933 22.4057 24.7133 22.0863 24.7133 21.6938C24.7133 21.3013 24.3933 20.9819 24.0001 20.9819C23.6076 20.9819 23.2883 21.3006 23.2883 21.6938ZM27.3489 24.1032H31.2991V27.979H27.3489V24.1032ZM28.4433 26.8338H29.0452V26.3194H28.4433V26.8338ZM29.0452 25.7631V25.2481H28.4433V25.7631H29.0452ZM29.6021 26.8338H30.2039V26.3194H29.6021V26.8338ZM30.2039 25.2482H29.6021V25.7632H30.2039V25.2482Z" fill="#97A4AE"/>
              </g>
              </svg>
              `,
  FLOOR_THROUGH = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_772_62528)">
                  <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
                  <path d="M28.6605 15.8333H22.0065C21.2659 15.8333 20.6665 16.4326 20.6665 17.1733V20.9313L17.5319 24.026C17.4379 24.1188 17.3737 24.2376 17.3474 24.367C17.321 24.4964 17.3337 24.6308 17.3839 24.753C17.434 24.8752 17.5193 24.9798 17.629 25.0534C17.7387 25.1271 17.8678 25.1665 17.9999 25.1666V28.5C17.9999 28.6768 18.0701 28.8464 18.1951 28.9714C18.3201 29.0964 18.4897 29.1666 18.6665 29.1666H29.3332C29.51 29.1666 29.6796 29.0964 29.8046 28.9714C29.9296 28.8464 29.9998 28.6768 29.9998 28.5V17.1726C29.9998 16.4326 29.4012 15.8333 28.6605 15.8333ZM23.2812 27.8333H19.3332V24.1213L21.3245 22.1553L23.2812 24.1573V27.8333ZM24.6665 20.5H23.3332V19.1666H24.6665V20.5ZM27.3332 25.8333H25.9998V24.5H27.3332V25.8333ZM27.3332 23.1666H25.9998V21.8333H27.3332V23.1666ZM27.3332 20.5H25.9998V19.1666H27.3332V20.5Z" fill="#97A4AE"/>
                  <path d="M20.6665 24.5H21.9998V25.8333H20.6665V24.5Z" fill="#97A4AE"/>
                  </g>
                  </svg>
                  `,
  DUPLEX = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_772_62492)">
            <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
            <path d="M27.8683 18.9469L25.8558 17.5963H28.2695L29.9214 18.9469H27.8683ZM24.0002 17.7357C24.0546 17.7357 24.1083 17.7519 24.1552 17.7832L27.544 20.0569V19.3994L24.0003 17.0213L20.4565 19.3994V20.0569L23.8453 17.7832C23.8922 17.7519 23.946 17.7357 24.0003 17.7357H24.0002ZM28.1002 19.5038V20.5795C28.1002 20.682 28.0433 20.7763 27.9527 20.8251C27.9115 20.847 27.8671 20.8576 27.8221 20.8576C27.7677 20.8576 27.7133 20.842 27.6665 20.8107L27.3483 20.597V21.6388H29.5778L29.5784 19.5038L28.1002 19.5038ZM27.349 23.5464H31.8402L30.189 22.1957H27.349V23.5464ZM16.1602 23.5464H20.6527V22.1957H17.8114L16.1602 23.5464ZM16.702 24.1032H20.6526V27.979H16.702V24.1032ZM17.7964 26.8338H18.3983V26.3194H17.7964V26.8338ZM18.9552 25.2482V25.7632H19.5583V25.2482H18.9552ZM18.9552 26.3194V26.8338H19.5583V26.3194H18.9552ZM17.7964 25.2482V25.7632H18.3983V25.2482H17.7964ZM24.0001 18.3495L26.792 20.2232V27.979H24.8369V25.0996H23.1639V27.9783H21.2088L21.2094 20.2226L24.0001 18.3495ZM23.2883 21.6938C23.2883 22.0863 23.6076 22.4057 24.0001 22.4057C24.3933 22.4057 24.7133 22.0863 24.7133 21.6938C24.7133 21.3013 24.3933 20.9819 24.0001 20.9819C23.6076 20.9819 23.2883 21.3006 23.2883 21.6938ZM27.3489 24.1032H31.2991V27.979H27.3489V24.1032ZM28.4433 26.8338H29.0452V26.3194H28.4433V26.8338ZM29.0452 25.7631V25.2481H28.4433V25.7631H29.0452ZM29.6021 26.8338H30.2039V26.3194H29.6021V26.8338ZM30.2039 25.2482H29.6021V25.7632H30.2039V25.2482Z" fill="#97A4AE"/>
            </g>
            </svg>
            `,
  LAND = `<svg preserveAspectRatio="xMidYMid meet" width="48" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_772_62530)">
          <rect x="12" y="10.5" width="24" height="24" rx="12" fill="white" shape-rendering="crispEdges"/>
          <path d="M31.5682 28.692L29.4562 23.716C29.3922 23.572 29.2482 23.476 29.0882 23.476H25.5682V25.22C25.5682 26.084 24.8642 26.804 23.9842 26.804C23.1202 26.804 22.4002 26.1 22.4002 25.22L22.4002 23.476H18.8802C18.7202 23.476 18.5762 23.572 18.5122 23.716L16.4322 28.692C16.3842 28.804 16.4002 28.948 16.4642 29.06C16.5442 29.172 16.6562 29.236 16.8002 29.236H31.2002C31.3282 29.236 31.4562 29.172 31.5202 29.06C31.6162 28.9481 31.6162 28.8041 31.5682 28.692H31.5682Z" fill="#97A4AE"/>
          <path d="M20.4322 21.252H23.4082V25.204C23.4082 25.524 23.6801 25.796 24.0001 25.796C24.3201 25.796 24.5921 25.524 24.5921 25.204L24.5922 21.252H27.5682C27.7922 21.252 27.9682 21.076 27.9682 20.852L27.9682 16.164C27.9682 15.94 27.7922 15.764 27.5682 15.764H20.4322C20.2082 15.764 20.0322 15.94 20.0322 16.164V20.852C20.0322 21.0759 20.2083 21.2519 20.4322 21.2519L20.4322 21.252ZM22.0002 17.22H25.9842C26.2082 17.22 26.3842 17.396 26.3842 17.62C26.3842 17.844 26.2082 18.02 25.9842 18.02H22.0002C21.7762 18.02 21.6002 17.844 21.6002 17.62C21.6162 17.396 21.7921 17.22 22.0002 17.22ZM22.0002 19.012H25.9842C26.2082 19.012 26.3842 19.188 26.3842 19.412C26.3842 19.636 26.2082 19.812 25.9842 19.812H22.0002C21.7762 19.812 21.6002 19.636 21.6002 19.412C21.6162 19.188 21.7921 19.012 22.0002 19.012Z" fill="#97A4AE"/>
          </g>
          </svg>
          `,
}
