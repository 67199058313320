import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutDirectionModule } from '@app/shared/directives';
import { SafeModule } from '@app/shared/pipes';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { CustomYoutubePlayerModule } from '../custom-youtube-player/custom-youtube-player.module';
import { ThumbsGallerySliderComponent } from './components/thumbs-gallery-slider/thumbs-gallery-slider.component';
import { ThumbsGalleryComponent } from './thumbs-gallery.component';

@NgModule({
  declarations: [ThumbsGalleryComponent, ThumbsGallerySliderComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    SwiperModule,
    TranslateModule,
    SafeModule,
    LayoutDirectionModule,
    CustomYoutubePlayerModule,
  ],
  exports: [ThumbsGalleryComponent, ThumbsGallerySliderComponent],
})
export class ThumbsGalleryModule {}
