export class WaselInfo {
  relationships = {};
  constructor(
    public id?: number,
    public building_number_field?: string,
    public street_name_arabic_field?: string,
    public district_area_arabic_field?: string,
    public city_name_arabic_field?: string,
    public zip_code_field?: string,
    public abbreviated_address?: string
  ) {}
}
