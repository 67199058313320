import { Injectable } from '@angular/core';
import { Endpoints } from '@app/helpers/endpoints';
import { AttributeOption, ParamsInterface } from '@app/interfaces';
import {
  Beneficiary,
  Booking,
  Deed,
  FamilyMember,
  FinancialAdvisory,
  FinancialRecommendation,
  JsonApi,
  MediaObject,
  RespondedPhoneNumber,
  ProjectUnit,
  WaselInfo,
} from '@app/models';
import { Payment } from '@app/models/payment';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { BookingTokenService } from '../ui/booking-token.service';
import { QueryPollingService } from './query-polling.service';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryService {
  private mainIntermediaryV4Url = Endpoints.MAIN_INTERMEDIARY_V4;
  private authV4Url = Endpoints.AUTH_API_URL;
  private beneficiaryUrlV3 = Endpoints.MAIN_INTERMEDIARY_V3;
  private beneficiaryUrlV5 = Endpoints.MAIN_INTERMEDIARY_V5;

  public beneSetFromLandsActionBox!: Beneficiary;

  private beneDataObs?: Observable<Beneficiary>;

  constructor(
    private api: ApiService,
    private bookingTokenService: BookingTokenService,
    private queryPollingService: QueryPollingService
  ) {}

  me(
    refresh = true,
    includes = [
      'beneficiary_assets_detail',
      'beneficiary_application',
      'active_booking',
      'active_subsidy_contract',
      'active_booking_session',
      'applicable_online_lending_booking',
    ]
  ): Observable<Beneficiary> {
    if (!this.beneDataObs || refresh) {
      this.beneDataObs = this.api
        .apiGet(`${this.mainIntermediaryV4Url}/beneficiary/me`, undefined, {
          include: includes,
        })
        .pipe(
          map((res) => {
            const bene = JsonApi.parseJsonApi(Beneficiary, res.data, res.included);
            this.bookingTokenService.setSession(bene.active_booking_session!);
            return JsonApi.parseJsonApi(Beneficiary, res.data, res.included);
          }),
          shareReplay()
        );
    }
    return this.beneDataObs;
  }

  updateAssetsDetails(data: any): Observable<Beneficiary> {
    return this.api
      .apiPut(`${this.beneficiaryUrlV3}/beneficiary/beneficiary_assets_details`, '', data)
      .pipe(map((res: any) => JsonApi.parseJsonApi(Beneficiary, res.data)));
  }

  updateAssetsDetailsV5(data: any): Observable<Beneficiary> {
    return this.api
      .apiPut(`${this.beneficiaryUrlV5}/beneficiary/beneficiary_assets_details`, '', data)
      .pipe(map((res: any) => JsonApi.parseJsonApi(Beneficiary, res.data, res.included)));
  }

  updateBeneficiary(data: any): Observable<Beneficiary> {
    return this.api
      .apiPut(`${this.mainIntermediaryV4Url}/beneficiary/me`, undefined, data)
      .pipe(map((res) => JsonApi.parseJsonApi(Beneficiary, res.data)));
  }

  updatePhoneNumber(data: any): Observable<RespondedPhoneNumber> {
    return this.api
      .apiPut(`${this.authV4Url}/beneficiary_mobile_update`, undefined, data)
      .pipe(map((res) => JsonApi.parseJsonApi(RespondedPhoneNumber, res.data)));
  }

  getAttributeOptions(): Observable<AttributeOption> {
    return this.api
      .apiGet(`${this.beneficiaryUrlV3}/beneficiary/attributes_options`, undefined, {}, 'application/json', false)
      .pipe(
        map((res) => {
          return res.data;
        }, shareReplay({ bufferSize: 1, refCount: true }))
      );
  }

  getPayments<T>(params: ParamsInterface): Observable<{ data: Payment[]; meta: T }> {
    return this.api.apiGet(`${this.mainIntermediaryV4Url}/beneficiary/payment_informations`, undefined, params).pipe(
      map((res) => {
        return {
          data: res?.data.map((item: any) => JsonApi.parseJsonApi(Payment, item)),
          meta: res?.meta.total_count,
        };
      })
    );
  }

  getDeeds(): Observable<Deed[]> {
    return this.api.apiGet(`${this.mainIntermediaryV4Url}/beneficiary/deeds`).pipe(
      map((res) => {
        const deeds = res.data.map((deed: any) => {
          return JsonApi.parseJsonApi(Deed, deed);
        });
        return deeds;
      })
    );
  }

  getBookingLatest() {
    return this.api.apiGet(`${this.beneficiaryUrlV3}/beneficiary/bookings/latest?`).pipe(
      map((res) => {
        const booking = JsonApi.parseJsonApi(Booking, res.data);
        if (booking?.project_media) {
          booking.project_media = new MediaObject(booking.project_media);
        }
        return booking;
      })
    );
  }

  getLandActiveBooking() {
    return this.api.apiGet(`${this.mainIntermediaryV4Url}/beneficiary/bookings/active_land_booking`).pipe(
      map((res) => {
        const booking = JsonApi.parseJsonApi(Booking, res.data);
        if (booking?.project_media) {
          booking.project_media = new MediaObject(booking.project_media);
        }
        return booking;
      })
    );
  }

  fetchFinancialAdvisory() {
    return this.api
      .apiGet(`${this.beneficiaryUrlV3}/beneficiary/financial_advisory`)
      .pipe(map((res: any) => JsonApi.parseJsonApi(FinancialAdvisory, res.data)));
  }

  fetchRecommendations() {
    return this.api.apiGet(`${this.beneficiaryUrlV3}/beneficiary/financial_advisory/recommendations`).pipe(
      map((res: any) => {
        const recommendations = res.data
          ? res.data.map((recommendation: any) => JsonApi.parseJsonApi(FinancialRecommendation, recommendation))
          : [];
        return recommendations;
      })
    );
  }

  updateFamilyMemberName(payload: any): Observable<FamilyMember> {
    return this.api
      .apiPost(`${this.beneficiaryUrlV3}/beneficiary/family_members/update_name`, payload, undefined)
      .pipe(map((res: any) => JsonApi.parseJsonApi(FamilyMember, res.data)));
  }

  getMyWallet(): Observable<any> {
    return this.api.apiGet(`${this.beneficiaryUrlV5}/beneficiary/azm_wallets/`).pipe(
      switchMap((res: any) => {
        if (res?.data?.topic) {
          return this.queryPollingService.polling(res.data.topic, res.data.request_id);
        } else {
          throw new Error('unexpected behavior');
        }
      })
    );
  }

  getMyWalletTransactions(params: ParamsInterface): Observable<any> {
    return this.api.apiGet(`${this.beneficiaryUrlV5}/beneficiary/azm_wallets/transactions`, undefined, params).pipe(
      switchMap((res: any) => {
        if (res?.data?.topic) {
          return this.queryPollingService.polling(res.data.topic, res.data.request_id);
        } else {
          throw new Error('unexpected behavior');
        }
      })
    );
  }

  createMyWallet(): Observable<any> {
    return this.api.apiPost(`${this.beneficiaryUrlV5}/beneficiary/azm_parties/`, {}).pipe(
      switchMap((res: any) => {
        if (res?.data?.topic) {
          return this.queryPollingService.polling(res.data.topic, res.data.request_id);
        } else {
          throw new Error('unexpected behavior');
        }
      })
    );
  }

  updateBankAccount(bankInfo: object): Observable<any> {
    return this.api.apiPut(`${this.beneficiaryUrlV5}/beneficiary/azm_wallets/update_withdraw_info`, '', bankInfo).pipe(
      switchMap((res: any) => {
        if (res?.data?.topic) {
          return this.queryPollingService.polling(res.data.topic, res.data.request_id);
        } else {
          throw new Error('unexpected behavior');
        }
      })
    );
  }

  walletWithdraw(amount: string, uuid: string, otp: string): Observable<any> {
    return this.api
      .apiPost(`${this.beneficiaryUrlV5}/beneficiary/azm_wallets/withdraw`, {
        amount,
        uuid,
        otp,
      })
      .pipe(
        switchMap((res: any) => {
          if (res?.data?.topic) {
            return this.queryPollingService.polling(res.data.topic, res.data.request_id);
          } else {
            throw new Error('unexpected behavior');
          }
        })
      );
  }

  removeBookingToken() {
    return this.api
      .apiPut(`${this.mainIntermediaryV4Url}/beneficiary/remove_booking_token`)
      .pipe(map((res: any) => res));
  }

  getWaselInformation(): Observable<WaselInfo> {
    return this.api
      .apiGet(`${this.mainIntermediaryV4Url}/beneficiary/wasel_informations`, undefined)
      .pipe(map((res: any) => JsonApi.parseJsonApi(WaselInfo, res.data)));
  }
}
