import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

/**
 *
 * @param control FormControl
 * @returns ValidationErrors | null
 *
 * # Usage
 * ```ts
 * const myForm = this.formBuilder.group({
 *   fieldName: ['', [Validators.required, Validators.minLength(6)]],
 * }
 * ```
 *
 * ```html
 * <input formControlName="fieldName">
 * <span class="text-danger" *ngFor="let error of myForm?.controls.fieldName | errorsList">
 * {{ error.message | translate: { value: error.params } }}
 * </span>
 * ```
 */

// @dynamic
@Pipe({
  name: 'errorsList',
  pure: false,
})
export class ErrorsListPipe implements PipeTransform {
  // @dynamic
  private static readonly errorMessages: { [key: string]: () => string } = {
    required: () => 'ERRORS.IS_REQUIRED',
    minlength: () => 'ERRORS.MIN_LENGTH',
    maxlength: () => 'ERRORS.MAX_LENGTH',
    pattern: () => 'ERRORS.INVALID_FORMAT',
    min: () => 'ERRORS.MIN',
    max: () => 'ERRORS.MAX',
    invalid: () => 'ERRORS.INVALID',
    pw_not_uppercase_at_least: () => 'ERRORS.PW_NOT_UPPERCASE_AT_LEAST',
    pw_not_lowercase_at_least: () => 'ERRORS.PW_NOT_LOWERCASE_AT_LEAST',
    pw_not_number_character_at_least: () => 'ERRORS.PW_NOT_NUMBER_CHARACTER_AT_LEAST',
    pw_not_special_character_at_least: () => 'ERRORS.PW_NOT_SPECIAL_CHARACTER_AT_LEAST',
    identityNumberStructure: () => 'ERRORS.IDENTITY_NUMBER_STRUCTURE',
    phoneNumberStructure: () => 'ERRORS.phone_number_structure',
    email: () => 'ERRORS.INVALID_EMAIL_FORMAT',
    file_size_exceed_maximium: () => 'ERRORS.FILE_SIZE_TOO_BIG',
    beforeHijriToday: () => 'ERRORS.BEFORE_HIJRI_TODAY',
    invalid_password: () => 'ERRORS.INVALID_PASSWORD',
    beforeToday: () => 'ERRORS.BEFORE_TODAY',
    afterSignedDate: () => 'ERRORS.AFTER_SIGNED_DATE',
    invalidDOBGregorian: () => 'ERRORS.INVALID_DOB_GREGORIAN',
    notAllUploaded: () => '',
    notEmptyObject: () => '',
  };

  static hasErrors(control: any): boolean {
    return control && control.errors && (control.dirty || control.touched);
  }

  static getMessage(type: string, params: any) {
    const predefinedType = [
      'required',
      'minlength',
      'maxlength',
      'pattern',
      'min',
      'max',
      'invalid',
      'pw_not_uppercase_at_least',
      'pw_not_lowercase_at_least',
      'pw_not_number_character_at_least',
      'pw_not_special_character_at_least',
      'identityNumberStructure',
      'phoneNumberStructure',
      'email',
      'file_size_exceed_maximium',
      'beforeHijriToday',
      'invalid_password',
      'beforeToday',
      'afterSignedDate',
      'invalidDOBGregorian',
      'notAllUploaded',
      'notEmptyObject',
    ];

    if (predefinedType.indexOf(type) > -1) {
      return { message: ErrorsListPipe.errorMessages[type](), params };
    } else {
      if ((typeof type === 'string' && type !== 'mask') || params instanceof String) {
        return { message: type };
      }
      return params;
    }
  }

  transform(control: AbstractControlDirective | AbstractControl | undefined, args?: any): any {
    return ErrorsListPipe.hasErrors(control)
      ? control?.errors &&
          Object.keys(control?.errors).map((field) =>
            ErrorsListPipe.getMessage(field, control?.errors && control?.errors[field])
          )
      : [];
  }
}
